import React, { useState } from "react";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";

interface YearDropdownProps {
  onYearChange?: (year: string) => void;
  initialValue?: string;
}

const years = ["2022-2023", "2023-2024", "2024-2025"];

const YearDropdown: React.FC<YearDropdownProps> = ({
  onYearChange,
  initialValue,
}) => {
  const [year, setYear] = useState(initialValue);

  const handleChange = (event: SelectChangeEvent) => {
    setYear(event.target.value as string);
    if (onYearChange) {
      onYearChange(event.target.value as string);
    }
  };
  return (
    <FormControl fullWidth>
      <InputLabel id="year-select-label">Year</InputLabel>
      <Select
        labelId="year-select-label"
        id="year-select"
        value={year}
        label="Year"
        onChange={handleChange}
        sx={{ width: 150, height: 40 }}
      >
        {years.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export { YearDropdown };
