import { useSelector } from 'react-redux';
import { RootState } from '../../store';

export const checkPermissions = (
  code: string,
  actionTypes: string[],
  checkSubModule: boolean = false
): boolean => {
  const isSuperadmin = useSelector((state: RootState) => state.roles.isSuperadmin);
  if (isSuperadmin) {
    return true;
  }

  const roles = useSelector((state: RootState) => state.roles.roles);
  return roles.some((role: any) => {
    if (role.module && role.module.length > 0) {
      const module = role.module.find((module: any) => 
        {
          return module.code === code;
        }
    );
      if (module && module.permissions) {
        if (actionTypes.some(actionType => module.permissions[actionType])) {
          return true;
        }
      }

      if (checkSubModule) {
        for (const module of role.module) {
          const subModule = module.subModules?.find(
            (subModule: any) => {
              return subModule.code === code
            }
          );
          if (subModule && subModule.permissions) {
            if (actionTypes.some(actionType => subModule.permissions[actionType])) {
              return true;
            }
          }
        }
      }
    }
    return false;
  });
};
