import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role } from './roleTypes';

interface RoleState {
    roles: Role[];
    isLoading: boolean;
    error: string | null;
    isSuperadmin: boolean;
  }
  
  const initialState: RoleState = {
    roles: [],
    isLoading: true,
    error: null,
    isSuperadmin: false
  };
  
  const roleSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
      setRoles(state, action: PayloadAction<Role[]>) {
        state.roles = action.payload; // Set roles from API
        state.isLoading = true;
      },
      setLoading(state, action: PayloadAction<boolean>) {
        state.isLoading = action.payload;
      },
      setError(state, action: PayloadAction<string | null>) {
        state.error = action.payload;
        state.isLoading = true;
      },
      clearRoles(state) {
        state.roles = [];
        state.isLoading = true;
        state.error = null;
      },
      setIsSuperadmin(state, action: PayloadAction<boolean>) {
        state.isSuperadmin = action.payload;
      },
    },
  });
  
  export const { setRoles, clearRoles, setLoading, setError, setIsSuperadmin } = roleSlice.actions;
  export default roleSlice.reducer;
