import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setRoles, setLoading, setError } from '../../redux/role/roleSlice';
import { fetchRoles } from '../../redux/role/roleService';
import { Role } from '../../redux/role/roleTypes';

const RoleManager: React.FC<any> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getRoles = async () => {
      dispatch(setLoading(true));
      
      try {
        const roles: Role[] = await fetchRoles(dispatch);
        // Check if the response has an error message
        if (roles && (roles as any)?.message) {
          dispatch(setError((roles as any).message)); // Set error if message is present
        } else {
          dispatch(setRoles(roles)); // Dispatch the roles to the store
        }
        dispatch(setLoading(false));
      } catch (error) {
        console.error('Failed to fetch roles:', error);
        dispatch(setError(error instanceof Error ? error.message : 'Unknown error occurred'));
        dispatch(setLoading(false));
      }
    };

    getRoles();
  }, [dispatch]);

  return null; // This component doesn't need to render anything
};

export default RoleManager;
