import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { ErrorBoundary, Loader } from './components';
import { theme } from './layouts';
import AppRouter from './routes/AppRouter';
import Snackbar from './utils/Snackbar';
import { Provider } from 'react-redux';
import store from './store';
import RoleManager from './components/RoleManager/RoleManager';

const TodoList = lazy(() => import('./modules/TodoList/TodoList'));
const App = () => (
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <CssBaseline />
        <RoleManager />
        <Router>
          <ErrorBoundary>
            <Suspense fallback={<Loader />}>
              <AppRouter />
              <Snackbar />
            </Suspense>
          </ErrorBoundary>
        </Router>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

export default App;
