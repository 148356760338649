interface ValidationRules {
    [key: string]: string;
}

interface CustomErrorMessages {
    [key: string]: {
      [key: string]: string;
    };
  }

export default function createFormValidator(rules: ValidationRules, customErrorMessages?: CustomErrorMessages) {
    return {
        errors: {} as { [key: string]: any },

        validateForm(data: { [key: string]: any }) {
            this.errors = {};
            const {filePath} = data;

            for (const field in rules) {
                const fieldRules = rules[field].split('|');
                const fieldLabelIn = field.replace(/([A-Z])/g, ' $1').toLowerCase(); // Convert camelCase to "Camel Case"
                const fieldLabel = fieldLabelIn.charAt(0).toUpperCase() + fieldLabelIn.slice(1);

                for (const rule of fieldRules) {
                    const [ruleName, ruleValue] = rule.split(':');
                    if (ruleName === 'required') {
               
                        if (!data[field]) {
                            if(!filePath){
                                this.errors[field] = (fieldLabel === 'Uploaded file') ? 'Please upload a valid format file'  :`Please provide ${fieldLabel}`;
                            }
                        } else if (typeof data[field] === 'string' && data[field].trim() === '') {
                            this.errors[field] = `Please provide ${fieldLabel}`;
                        } else if (Array.isArray(data[field])) {
                            const cleanedArray: (string | any)[] = data[field].filter((item: string | any) => {
                                if (typeof item === 'string') {
                                    return item.trim() !== '';
                                }
                                return true;
                            });
                            if (cleanedArray.length === 0) {
                                if (customErrorMessages && customErrorMessages[field] && customErrorMessages[field][ruleName]) {
                                    this.errors[field] = customErrorMessages[field][ruleName];
                                  } else {
                                    this.errors[field] = `Please provide at least one ${fieldLabel}`;
                                  }                            }
                        }
                    } else if (ruleName === 'string') {
                        if (typeof data[field] !== 'string') {
                            this.errors[field] = `${fieldLabel} must be a string`;
                        }
                    } else if (ruleName === 'limit') {
                        if (typeof data[field] === 'string' && data[field].length > Number(ruleValue)) {
                            this.errors[field] = `${fieldLabel} character limit is ${ruleValue}`;
                        }
                    } else if (ruleName === 'integer') {
                        if (data[field]) {
                            if (typeof data[field] === 'string') {
                                // Remove leading and trailing whitespace
                                data[field] = data[field].trim();
                                // Use a regular expression to check if it's a valid integer
                                if (!/^-?\d+$/.test(data[field])) {
                                    this.errors[field] = `${fieldLabel} must be an integer`;
                                }
                            } else if (!Number.isInteger(data[field])) {
                                this.errors[field] = `${fieldLabel} must be an integer`;
                            }
                        }
                    } else if (ruleName === 'bigInteger') {
                        if (!Number.isInteger(data[field])) {
                            this.errors[field] = `${fieldLabel} must be a big integer`;
                        }
                    } else if (ruleName === 'smallInteger') {
                        if (!Number.isInteger(data[field])) {
                            this.errors[field] = `${fieldLabel} must be a small integer`;
                        }
                    } else if (ruleName === 'text') {
                        if (typeof data[field] !== 'string') {
                            this.errors[field] = `${fieldLabel} must be text`;
                        }
                    } else if (ruleName === 'boolean') {
                        if (typeof data[field] !== 'boolean') {
                            this.errors[field] = `${fieldLabel} must be a boolean`;
                        }
                    } else if (ruleName === 'date') {
                        if (!/^\d{4}-\d{2}-\d{2}$/.test(data[field])) {
                            this.errors[field] = `${fieldLabel} must be a valid date (yyyy-mm-dd)`;
                        }
                    } else if (ruleName === 'dateTime') {
                        if (!/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(data[field])) {
                            this.errors[field] = `${fieldLabel} must be a valid date and time (yyyy-mm-dd hh:mm:ss)`;
                        }
                    } else if (ruleName === 'decimal') {
                        if (isNaN(data[field]) || data[field] % 1 !== 0) {
                            this.errors[field] = `${fieldLabel} must be a decimal number`;
                        }
                    } else if (ruleName === 'float') {
                        if (isNaN(data[field]) || data[field] % 1 === 0) {
                            this.errors[field] = `${fieldLabel} must be a floating-point number`;
                        }
                    } else if (ruleName === 'double') {
                        if (isNaN(data[field]) || data[field] % 1 === 0) {
                            this.errors[field] = `${fieldLabel} must be a double-precision floating-point number`;
                        }
                    } else if (ruleName === 'enum') {
                        if (!ruleValue.split(',').includes(data[field])) {
                            this.errors[field] = `Invalid ${fieldLabel}`;
                        }
                    } else if (ruleName === 'json') {
                        try {
                            JSON.parse(data[field]);
                        } catch (e) {
                            this.errors[field] = `Invalid JSON format for ${fieldLabel}`;
                        }
                    } else if (ruleName === 'jsonb') {
                        // Add validation for jsonb
                    } else if (ruleName === 'char') {
                        if (typeof data[field] !== 'string' || data[field].length !== 1) {
                            this.errors[field] = `${fieldLabel} must be a single character`;
                        }
                    } else if (ruleName === 'time') {
                        if (!/^(?:[01]\d|2[0-3]):[0-5]\d$/.test(data[field])) {
                            this.errors[field] = `${fieldLabel} must be a valid time (HH:MM)`;
                        }
                    } else if (ruleName === 'timestamp') {
                        if (!/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(data[field])) {
                            this.errors[field] = `${fieldLabel} must be a valid timestamp (yyyy-mm-dd hh:mm:ss)`;
                        }
                    } else if (ruleName === 'ipAddress') {
                        if (!/^(\d{1,3}\.){3}\d{1,3}$/.test(data[field])) {
                            this.errors[field] = `Invalid IP address for ${fieldLabel}`;
                        }
                    } else if (ruleName === 'max') {
                        if (typeof data[field] === 'string' && data[field].length > Number(ruleValue)) {
                            this.errors[field] = `${fieldLabel} must be at most ${ruleValue} characters long`;
                        }
                        if (!isNaN(data[field]) && data[field] > Number(ruleValue)) {
                            this.errors[field] = `${fieldLabel} should not be more than ${ruleValue}`;
                        }
                    } else if (ruleName === 'min') {
                        if (typeof data[field] === 'string' && data[field].length < Number(ruleValue)) {
                            this.errors[field] = `${fieldLabel} must be at least ${ruleValue} characters long`;
                        }
                        if (!isNaN(data[field]) && data[field] < Number(ruleValue)) {
                            this.errors[field] = `${fieldLabel} should not be less than ${ruleValue}`;
                        }
                    } else if (ruleName === 'email') {
                        if (!/^\S+@\S+\.\S+$/.test(data[field])) {
                            this.errors[field] = `Invalid email address for ${fieldLabel}`;
                        }
                    } else if (ruleName === 'in') {
                        if (!ruleValue.split(',').includes(data[field])) {
                            this.errors[field] = `Invalid value for ${fieldLabel}`;
                        }
                    } else if (ruleName === 'not_in') {
                        if (ruleValue.split(',').includes(data[field])) {
                            this.errors[field] = `Invalid value for ${fieldLabel}`;
                        }
                    } else if (ruleName === 'array') {
                        if (!Array.isArray(data[field])) {
                            this.errors[field] = `${fieldLabel} must be an array`;
                        }
                    } else if (ruleName === 'regex') {
                        if (data[field]) {
                            const regexPattern = new RegExp(ruleValue.slice(1, -1)); // Remove leading and trailing slashes
                            if (typeof data[field] === 'string' && !regexPattern.test(data[field])) {
                                this.errors[field] = `Invalid format for ${fieldLabel}`;
                            }
                        }
                    } else if (ruleName === 'accepted') {
                        if (data[field] !== true) {
                            this.errors[field] = `You must accept ${fieldLabel}`;
                        }
                    } else if (ruleName === 'date_format') {
                        if (!new RegExp(ruleValue).test(data[field])) {
                            this.errors[field] = `Invalid date format for ${fieldLabel}`;
                        }
                    } else if (ruleName === 'numeric') {
                        if (isNaN(data[field])) {
                            this.errors[field] = `Invalid numeric value for ${fieldLabel}`;
                        }
                    } else if (ruleName === 'boolean') {
                        if (typeof data[field] !== 'boolean') {
                            this.errors[field] = `${fieldLabel} must be a boolean`;
                        }
                    } else if (ruleName === 'after') {
                        const date = new Date(data[field]);
                        if (isNaN(date.getTime()) || date <= new Date(ruleValue)) {
                            this.errors[field] = `${fieldLabel} must be after ${ruleValue}`;
                        }
                    } else if (ruleName === 'before') {
                        const date = new Date(data[field]);
                        if (isNaN(date.getTime()) || date >= new Date(ruleValue)) {
                            this.errors[field] = `${fieldLabel} must be before ${ruleValue}`;
                        }
                    } else if (ruleName === 'digits') {
                        if (typeof data[field] !== 'number' || data[field].toString().length !== Number(ruleValue)) {
                            this.errors[field] = `${fieldLabel} must have ${ruleValue} digits`;
                        }
                    } else if (ruleName === 'digits_between') {
                        const value = data[field].toString().length;
                        const [min, max] = ruleValue.split(',');
                        if (typeof data[field] !== 'number' || value < Number(min) || value > Number(max)) {
                            this.errors[field] = `${fieldLabel} must have between ${min} and ${max} digits`;
                        }
                    } else if (ruleName === 'confirmed') {
                        if (data[field] !== data[ruleValue]) {
                            this.errors[field] = `The ${fieldLabel} confirmation does not match`;
                        }
                    } else if (ruleName === 'size') {
                        if (data[field].length !== Number(ruleValue)) {
                            this.errors[field] = `${fieldLabel} must be of size ${ruleValue}`;
                        }
                    } else if (ruleName === 'mimes') {
                        const allowedMimes = ruleValue.split(',');
                        if (data[field]) {
                            if (typeof data[field] === 'string') {
                                const fileExtension = data[field]?.split('.')?.pop().toLowerCase(); // Get the file extension
                                if (!allowedMimes.includes(fileExtension)) {
                                    this.errors[field] = `Invalid file extension for ${fieldLabel}. Allowed extensions are ${allowedMimes.join(', ')}`;
                                }
                            } else {
                                // Handle the case where data[field] is not a string (e.g., not a MIME type)
                                this.errors[field] = `Invalid file type for ${fieldLabel}`;
                            }
                        }
                    } else if (ruleName === 'nullable') {

                    }
                    // Add more rules as needed
                }
            }

            return this.errors;
        }
    };
}