import { SvgIcon } from "@mui/material";
import styled from "styled-components";
import Switch, { SwitchProps } from "@mui/material/Switch";

const ListTableWrapper = styled.div`
  padding: 0;
  .list_table-btn-space {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .filter-btn {
      margin-right: 20px;
      font-size: 16px;
      font-weight: 500;
      font-style: Inter;
      line-height: 19.36px;
      color: #2559c3;
      border: 1px solid #2559c3;
      padding: 16px 24px;

      .filter-down-icon {
        margin-bottom: -15px;
      }
    }

    .addlist_table-btn {
      font-size: 16px;
      font-weight: 500;
      font-style: Inter;
      line-height: 19.36px;
      color: #ffffff;
      padding: 16px 24px;
    }
  }

  .employee-nav {
    margin-top: 40px;

    .live-rank-tab-container {
      text-transform: capitalize;
      font-style: Inter;
      font-weight: 400;
      font-size: 14px;
      line-height: 16.94px;
      color: #848282;
    }
  }

  .list_table-main-table {
    margin-top: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 12px 24px 13px;
    border: 1px solid rgb(211, 211, 211);
    min-height: auto;
    text-align: left;

    .certificate-heading {
      padding: 10px;
      font-family: Lato;
    }

    .override-tabpanel {
      padding: unset;
    }

    .table-row-item-cell {
      width: 100%;
      display: flex;
      align-items: center;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 14px;
      background: #e9eef9;
      padding: 8px 16px;
      justify-content: space-between;
      height: 48px;
      margin: 0;
      border-radius: 10px;
      border: 1px solid rgb(211, 211, 211);
    }

    .table-header-modify {
      width: 103.2%;
      padding: 0px 32px;
      margin-left: -24px;
      border-radius: unset;
      border-left: unset;
      border-right: unset;
    }

    .table-row-item {
      display: flex;
      align-items: center;
      border-radius: 0;
      border: 1px solid rgb(211, 211, 211);
      background: #fff;
      margin: 7px 0;
      justify-content: flex-end;
      height: 84px;
      overflow: hidden;
      padding: 0px 0;
      border-radius: 8px;

      .list-list_table {
        .status-pending {
          border-radius: 5px;
          padding: 0px 10px;
          gap: 10px;
          background-color: #ffeed8;
          font-family: Lato;
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          color: #eda13b;
        }

        .status-approved {
          border-radius: 5px;
          padding: 0px 10px;
          gap: 10px;
          background-color: #dbffd8;
          font-family: Lato;
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          color: #0eaa00;
        }

        .status-rejected {
          border-radius: 5px;
          padding: 0px 10px;
          gap: 10px;
          background-color: #ffcccc;
          font-family: Lato;
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          color: #ff0000;
        }

        .listIem-text {
          font-weight: 900;
          font-size: 12px;
          font-family: Inter;
          padding: 0px;

          span {
            font-family: Inter;
            font-size: 12px;
            font-weight: 500;
            color: #000000;
          }
        }
      }
    }
  }

  .table-header-modify > .list_table-table-head:first-child {
    margin-left: 20px;
  }

  .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    height: 37px;
    margin-top: 7px;
  }

  .list_table-table-head {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    color: #06152b;
    text-transform: uppercase;
    text-align: left;
    flex: 1 1 0;
    font-size: 12px;
    margin-left: 4px;
    font-weight: 500;
  }

  .list-list_table-box-wrap {
    flex: 0 1px auto;
    text-align: center;
    flex: 1 1 0;

    .list-list_table {
      padding: 8px 27px;
    }
  }

  .table-row-profile-container {
    width: 8%;
  }

  .primary-icon {
    color: #2559c3;
  }
  .secondary-icon {
    color: gray;
  }
  .success-icon {
    color: #2559c3;
  }
  .danger-icon {
    color: red;
  }

  .status-field-wrap {
    display: flex;
    justify-content: center;
    flex: 1 1 0;
  }

  .list-table-status-head {
    text-align: center;
  }

  .list-table-action-head {
    text-align: center;
  }
  .actions-field-wrap {
    flex: 1 1 0;
    display: flex;
    justify-content: center;
  }
`;

const StyledListTableIcon = styled(SvgIcon)`
  &.list_table-icon {
    margin-left: 10px;
    padding-right: 0px;
  }
  &.plus-icon {
    margin-left: 10px;
    padding: 0px;
  }
`;

const AntSwitch = styled(Switch)`
  width: 40px;
  height: 20px;
  padding: 0;

  & .MuiSwitch-switchBase {
    color: #818181;
    padding: 1px;

    &.Mui-checked + .MuiSwitch-track {
      background-color: #23bf58;
    }
    &.Mui-checked {
      left: -4px;
    }
  }

  & .MuiSwitch-thumb {
    color: white;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    margin-left: 2px;
  }

  & .MuiSwitch-track {
    border-radius: 20px;
    background-color: #818181;
    opacity: 1 !important;

    &::after,
    &::before {
      color: white;
      font-size: 11px;
      position: absolute;
      top: 6px;
    }

    &::after {
      left: 6px;
      padding: 3px;
      font-weight: bold;
    }

    &::before {
      right: 4px;
      padding: 3px;
      font-weight: bold;
    }
  }

  &.Mui-checked {
    color: #23bf58 !important;
    transform: translateX(24px) !important;
  }
`;

export { ListTableWrapper, StyledListTableIcon, AntSwitch };
