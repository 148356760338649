import * as React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkPermissions } from '../utils/roles/permissionUtils';
import moduleMappings from '../utils/roles/moduleMappings';

const drawerWidth = 120;
function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeItem, setActiveItem] = React.useState('/categories');
  const sidebarItems = moduleMappings;

  const handleItemClick = (route: any) => {
    navigate(route);
    setActiveItem(route);
  };

  React.useEffect(() => {
    setActiveItem(location.pathname);
  }, [location.pathname]);

  const list = () => (
    <List sx={{ paddingTop: '15px' }}>
      {sidebarItems
        .filter(({ code }) => checkPermissions(code, ['Read','Write','Edit','Delete']))
        .map(({ text, imageURL, route }) => (
          <ListItem
            sx={{
              alignContent: 'space-between',
              alignItems: 'center',
              color: '#fff',
              display: 'grid',
              justifyContent: 'center',
              borderLeft: activeItem === route ? '3px solid #DE4E35' : 'none',
            }}
            key={text}
            onClick={() => handleItemClick(route)}
          >
            <ListItemIcon
              sx={{ display: 'block', textAlign: 'center', cursor: 'pointer' }}
            >
              <img src={imageURL} alt='svg' />
            </ListItemIcon>
            <ListItemText
              sx={{ textAlign: 'center', cursor: 'pointer' }}
              primary={text}
            />
          </ListItem>
        ))}
    </List>
  );

  return (
    <Drawer
      className='side-bar-container'
      variant='permanent'
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          background: '#2559C3',
          paddingTop: '12px',
          overflowY: 'auto',
          scrollbarWidth: 'none',  // Hide scrollbar (Firefox)
          '&::-webkit-scrollbar': {
            display: 'none',  // Hide scrollbar (Chrome, Safari)
          },
        },
      }}
    >
      <Toolbar />
      {list()}
    </Drawer>
  );
}

export default Sidebar;
