import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Module, Permission } from './roleTypes';

interface PermissionsState {
  modules: Module[];
}

const initialState: PermissionsState = {
  modules: [], // Initialize with an empty array
};

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setModules(state, action: PayloadAction<Module[]>) {
      state.modules = action.payload; // Set the modules and their permissions
    },
    clearModules(state) {
      state.modules = []; // Clear the modules
    },
    updateModulePermissions(state, action: PayloadAction<{ moduleName: string; permissions: Permission }>) {
      const { moduleName, permissions } = action.payload;
      const module = state.modules.find((mod) => mod.module === moduleName);
      if (module) {
        module.permissions = permissions; // Update the module permissions
      }
    },
  },
});

export const { setModules, clearModules, updateModulePermissions } = permissionsSlice.actions;
export default permissionsSlice.reducer;
