import { Role } from './roleTypes';
import { config } from '../../config';
import { CommHandler } from '../../utils';
import { setIsSuperadmin } from './roleSlice';
import { Dispatch } from 'redux';
import { checkSuperadminStatus } from '../../utils/checkSuperAdmin';

export const fetchRoles = async (dispatch: Dispatch): Promise<Role[]> => {
    const email = localStorage.getItem('user');
    
    if (!email) {
        return Promise.reject({ message: "Email not found" });
    }
    const requestData = {
        email: email,
    }
    try {
        const response = await CommHandler.request({
            url: `${config.rbacApiBaseUrl}/role/user-role`,
            method: 'POST',
            data: requestData,
        });

        const roles = response.data;
        const isUserSuperadmin = await checkSuperadminStatus(email);
        dispatch(setIsSuperadmin(isUserSuperadmin));
        
        return roles;
    } catch (error) {
        console.error('Error fetching roles:', error);
        return Promise.reject({ message: 'Error fetching roles', error });
    }
};
