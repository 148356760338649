import React, { useState, ChangeEvent, useEffect, useCallback } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
  Stack,
  Autocomplete,
  TextField,
  Checkbox,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {
  Button,
  MessageAlert,
  Editor,
  MultiSelectDropdown,
  FormInput,
} from "../../../components";
import { ButtonColor } from "../../../components/Button/types";
import { CommHandler } from "../../../utils";
import { config } from "../../../config";
import { SelectChangeEvent } from "@mui/material/Select";
import createFormValidator from "../../../utils/FormValidator";
import { AddKRAFormWrapper } from "../../KRA/Component/AddKRAForm.styled";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

type RequestData = {
  department: string;
  buIds: string[];
  id?: string;
  hrIds: string[];
};

const validationRules = {
  title: "required| string",
  buHead: "required|array",
  hrBPs: "required|array",
};

const customErrorMessages = {
  title: {
    required: "Please Add Business Unit Name",
  },
  hrBPs: {
    required: "Please provide at least one HRBP",
  },
  buHead: {
    required: "Please provide at least one BU Head",
  },
};
const validator = createFormValidator(validationRules, customErrorMessages);

const debounce = (func: Function, delay: number) => {
  let timer: NodeJS.Timeout;
  return function (...args: any) {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};

const AddBuHead: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data = {} } = location.state;
  const [title, setTitle] = useState(data.title);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [alertType, setAlertType] = useState<"success" | "info" | "warning">("success");
  const [alertHeader, setAlertHeader] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedBuHead, setSelectedBuHead] = useState<any[]>([]);
  const [buHead, setBuHead] = useState<any[]>([]);
  const [hrBPs, setHrBPs] = useState<any[]>([]);
  const [selectedhrbps, setSelectedHrbps] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isTitleAvailable, setIsTitleAvailable] = useState(true);
  const [buProfiles, setBuProfiles] = useState<any[]>([]);

  async function fetchData() {
    setLoading(true);

    try {
      const [usersResponse, buProfilesResponse] = await Promise.all([
        CommHandler.request({
          url: `${config.apiBaseUrl}/users`,
          method: "GET",
        }),
        CommHandler.request({
          url: `${config.apiBaseUrl}/bu-profiles`,
          method: "GET",
        }),
      ]);
      const BuHeadList = usersResponse?.data?.map((item: any) => ({
        label: item.fullName,
        id: item?.profile?.user_id,
        email: item.email,
      }));
      setBuHead(BuHeadList);
      setHrBPs(BuHeadList);
      setBuProfiles(buProfilesResponse?.data || []);
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (data.id) {
      const selectedBuHeadData = buHead.find((item) => item.id === data.buHeadId);
      const selectedBuHead = selectedBuHeadData ? selectedBuHeadData : { label: data.buHead, id: data.buHeadId };
      setSelectedBuHead([selectedBuHead]);
  
      const selectedHrbpData = hrBPs.find((item) => item.id === data.hrBPsId);
      const selectedHrbp = selectedHrbpData ? selectedHrbpData : { label: data.hrBPs, id: data.hrBPsId };
      setSelectedHrbps([selectedHrbp]);
    }
  }, [data, buHead, hrBPs]);

  const onClose = () => {
    navigate("/bu");
  };

  const handleShowAlert = (
    header: string,
    message: string,
    type: "success" | "info" | "warning"
  ) => {
    setAlertType(type);
    setAlertHeader(header);
    setAlertMessage(message);
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    if (alertType === "success") {
      onClose();
    }
    setShowAlert(false);
  };

  const handleCloseSuccessAlert = () => {
    setShowSuccessAlert(false);
    onClose();
  };

  const handleConfirmCancel = () => {
    if (selectedBuHead.length > 0) {
      setShowConfirmationDialog(true);
    } else {
      onClose();
    }
  };

  const handleCancelConfirmation = () => {
    setShowConfirmationDialog(false);
  };

  const handleConfirmCancelConfirmation = () => {
    setShowConfirmationDialog(false);
    onClose();
  };

  const handleCancel = () => {
    if (selectedBuHead.length > 0 || title.length > 0) {
      setShowConfirmationDialog(true);
    } else {
      onClose();
    }
  };

  const checkTitleAvailability = useCallback(
    debounce((value: string) => {
      if (buProfiles.length === 0) {
        setIsTitleAvailable(true);
        setIsSubmitDisabled(false);
        setFormErrors((prevErrors) => {
          const { title, ...restErrors } = prevErrors;
          return restErrors;
        });
        return;
      }
      const isTitleExists = buProfiles.some(
        (profile) => profile.department === value
      );
      if (isTitleExists) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          title: "This Business Unit already exist.",
        }));
        setIsSubmitDisabled(true);
      } else {
        setFormErrors((prevErrors) => {
          const { title, ...restErrors } = prevErrors;
          return restErrors;
        });
        setIsSubmitDisabled(false);
      }
      setIsTitleAvailable(!isTitleExists);
    }, 500),
    [buProfiles]
  );

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTitle(value.substring(0,100));
    if (value.length > 100) {
      setFormErrors({
        ...formErrors,
        title: "Business Unit Name should not be more than 100 characters",
      });
    } else {
      setFormErrors({ ...formErrors, title: "" });
    }
    if (value.length <= 100) {
      checkTitleAvailability(value);
    }
  };

  const handleBuHeadChange = (newValue: any[]) => {
    setSelectedBuHead(newValue);
    if (newValue.length > 0) {
      setFormErrors((prevErrors) => {
        const { buHead, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  };
  
  const handleHrbpChange = (newValue: any[]) => {
    setSelectedHrbps(newValue);
    if (newValue.length > 0) {
      setFormErrors((prevErrors) => {
        const { hrBPs, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  };
  const handleSave = async () => {
    try {
      // const errors = validator.validateForm({ title, buHead, hrBPs });
      const errors = validator.validateForm({
        buHead: selectedBuHead,
        hrBPs: selectedhrbps,
        title,
      });
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      } else {
        setFormErrors({}); // Clear error messages when fields are filled with values
      }
      setIsSubmitDisabled(true);

      const requestData: RequestData = {
        department: title,
        buIds: selectedBuHead?.map((item) => item.id),
        hrIds: selectedhrbps?.map((item) => item.id),
      };

      if (data.id) {
        requestData.id = data?.id;
      }

      const response = await CommHandler.request({
        url: `${config.apiBaseUrl}/bu-profiles`,
        method: data.id ? "PUT" : "POST",
        data: requestData,
      });

      if (response.status === 200) {
        handleShowAlert(
          data.id ? "Edit Business Unit" : "Add Business Unit",
          data.id
            ? "Business Unit updated successfully!"
            : "Business Unit added successfully!",
          "success"
        );
      } else {
        setIsSubmitDisabled(false);
        const errorMessage =
          response.data && response.data.message
            ? response.data.message
            : "Some error occurred while saving!";
        console.error("Error:", errorMessage);
        handleShowAlert(
          data.id ? "Edit Business Unit" : "Add Business Unit",
          errorMessage,
          "warning"
        );
      }
    } 
    catch (error) {
      setIsSubmitDisabled(false);
      console.error("Error:", error);
      handleShowAlert(
        "Add/Edit Business Unit",
        "Some error occurred!",
        "warning"
      );
    }
  };

  return (
    <AddKRAFormWrapper>
      <Card variant="outlined" className="card-wrap">
        <CardHeader
          title={data.id ? "Edit Business Unit" : "Add Business Unit"}
        />
        <CardContent>
          <form>
            <div>
              <Box
                mt={2}
                display="flex"
                justifyContent="flex-end"
                className="add-kra-field"
              >
                <FormInput
                  inputId="basic-input"
                  required
                  inputBoxType="outlined"
                  label="Business Unit Name *"
                  type="input"
                  disabled={data.id ? true : false}
                  defaultValue={title}
                  onValueChange={handleTitleChange}
                  InputLabelShrink={false}
                  marginBottom={0}
                  error={!!formErrors.title}
                  helperText={formErrors.title}
                />
              </Box>
            </div>
            <div>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { width: "100%" },
                }}
                noValidate
                autoComplete="off"
                className="add-kra-field"
              >
                <InputLabel htmlFor="designation">BU Heads *</InputLabel>
                <FormControl fullWidth variant="outlined">
                  <Autocomplete
                    multiple
                    id="designation"
                    options={buHead}
                    value={selectedBuHead}
                    loading={loading}
                    onChange={(_, newValue) => handleBuHeadChange(newValue)}
                    disableCloseOnSelect
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option?.id}>
                          <Checkbox
                            checked={selectedBuHead.includes(option)}
                            indeterminate={false}
                          />
                          {option?.label} ({option?.email})
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Search BU Head list"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.startAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
                {formErrors.buHead && (
                  <div style={{ color: "red", marginTop: "5px" }}>
                    {formErrors.buHead}
                  </div>
                )}
              </Box>
            </div>
            <div>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { width: "100%" },
                }}
                noValidate
                autoComplete="off"
                className="add-kra-field"
              >
                <InputLabel htmlFor="designation">HRBPs *</InputLabel>
                <FormControl fullWidth variant="outlined">
                  <Autocomplete
                    multiple
                    id="designation"
                    options={hrBPs}
                    value={selectedhrbps}
                    loading={loading}
                    onChange={(_, newValue) => handleHrbpChange(newValue)}
                    disableCloseOnSelect
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option?.id}>
                          <Checkbox
                            checked={selectedhrbps?.includes(option)}
                            indeterminate={false}
                          />
                          {option?.label} ({option?.email})
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Search HRBP list"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.startAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
                {formErrors.hrBPs && (
                  <div style={{ color: "red", marginTop: "5px" }}>
                    {formErrors.hrBPs}
                  </div>
                )}
              </Box>
            </div>
            <Box
              mt={2}
              display="flex"
              className="admin_addnew_modal_footer"
              justifyContent="flex-end"
            >
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  color={ButtonColor.inherit}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={isSubmitDisabled}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </form>
        </CardContent>
        <Dialog className="btn_style_prt" open={showConfirmationDialog}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You have unsaved changes. Are you sure you want to cancel?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelConfirmation}>No</Button>
            <Button onClick={handleConfirmCancelConfirmation}>Yes</Button>
          </DialogActions>
        </Dialog>
        {showSuccessAlert && (
          <Alert onClose={handleCloseSuccessAlert} severity="success">
            <AlertTitle>Success</AlertTitle>
            {data.id
              ? "Business Unit updated successfully."
              : "Business Unit added successfully."}
            <Button
              variant="outlined"
              size="small"
              onClick={handleCloseSuccessAlert}
            >
              Close
            </Button>
          </Alert>
        )}
      </Card>
      <MessageAlert
        open={showAlert}
        onClose={handleCloseAlert}
        header={alertHeader ? alertHeader : "Alert"}
        message={alertMessage ? alertMessage : "An error occurred."}
        alertType={alertType}
      />
    </AddKRAFormWrapper>
  );
};

export default AddBuHead;
